:root {
  --main-bg: #f4f2ee;
}

body, html {
  height: 100%;
  width: 100%;
  background-color: var(--main-bg);
}

#root {
  height: 100%;
  width: 100%;
  background-color: var(--main-bg);
}

#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 4.5vh 3vw 4.5vh 3vw;
  background-color: var(--main-bg);
  box-sizing: border-box;
}

#header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#hf-spacer {
  flex-grow: 1;
  height: 100%;
}

#content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  user-select: none;
}


#auth-cont {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* on desktop, #auth-cont's padding: 0vh 30vw 10vh 30vw; */
/* on mobile, #auth-cont's padding: 0vh 15vw 10vh 15vw; */

@media only screen and (max-width: 800px) {
  #auth-cont {
    padding: 0vh 15vw 0vh 15vw;
  }
}

@media only screen and (min-width: 800px) {
  #auth-cont {
    padding: 0vh 25vw 0vh 25vw;
  }
}

#auth-logo-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh;
}

#auth-logo {
  max-height: 8vh;
  max-width: 40vw;
  height: 8vh;
  width: auto;
}

#auth-main-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-button {
  transition: all 0.2s ease-in-out;
  padding: 16px 26px;
  border-radius: 30px;
  width: 25vw;
  font-size: 18px;
  margin-top: 4vh;
  box-shadow: none;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-transform: none;
  border: solid 1px #5f5f5f;

  background-color: var(--main-bg);
  color: #5f5f5f;
}

.auth-button:hover {
  transition: all 0.2s ease-in-out;
  background-color: #cdcdcd;
}

.icon-button {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  border: none;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-unfilled {
  transition: all 0.2s ease-in-out;
  background-color: var(--main-bg);
  font-size: 22px;
  color: #aaa;
}

.icon-button-unfilled:hover {
  transition: all 0.2s ease-in-out;
  color: #888;
}

.icon-button-filled {
  transition: all 0.2s ease-in-out;
  background-color: #dedede;
  font-size: 18px;
  color: #aeaeae;
}

.icon-button-filled:hover {
  transition: all 0.2s ease-in-out;
  background-color: #d1d1d1;
}

.icon-button-invis {
  transition: all 0.2s ease-in-out;
  background-color: var(--main-bg);
}

.icon-button-invis > i {
  transition: all 0.2s ease-in-out;
  color: var(--main-bg);
}

.icon-button-invis:hover {
  transition: all 0.2s ease-in-out;
  background-color: var(--main-bg);
}

.icon-button-filled > .loading-icon {
  transform: scale(0.7);
  filter: invert(85%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(77%) contrast(106%);
}

.icon-button-unfilled > .loading-icon {
  transform: scale(0.7);
  filter: invert(85%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(77%) contrast(106%);
}

.orb > .loading-icon {
  transform: scale(3);
  filter: invert(25%) sepia(79%) saturate(7329%) hue-rotate(39deg) brightness(89%) contrast(94%);
}


#deactivate-btn {
  transition: all 0.5s ease-in-out;
  background-color: #c33d39;
  
  justify-content: center;
  align-items: center;

  display: flex;

  margin-right: 30px;
}

#deactivate-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: #ac3531;
}

#deactivate-btn:active {
  transition: all 0.2s ease-in-out;
  background-color: #932d29;
}

#deactivate-btn > .loading-icon {
  width: 20px;
  height: 20px;
  filter: invert(92%) sepia(6%) saturate(2715%) hue-rotate(299deg) brightness(105%) contrast(99%);
}


/* ~~~~ ORB ~~~~~ */

@keyframes orbWave1 {
  0%   {transform: scale(1.35);}
  50%  {transform: scale(1.55);}
  100% {transform: scale(1.35);}
}

@keyframes orbWave2 {
  0%   {transform: scale(1.6);}
  50%  {transform: scale(2.1);}
  100% {transform: scale(1.6);}
}


.orb-outer-1-animate-state-change {
  transition: all 0.3s ease-in-out;
  transform: scale(1.35);
}

.orb-outer-1-animate {
  animation-name: orbWave1;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-timing-function: linear;
}

.orb-outer-2-animate-state-change {
  transition: all 0.3s ease-in-out;
  transform: scale(1.6);
}

.orb-outer-2-animate {
  animation-name: orbWave2;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-delay: 0.3s;
}

.orb-cont {
  position: relative;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orb {
  transition: all 0.2s ease-in-out;
  width: 25vh;
  height: 25vh;
  border-radius: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  position: absolute;
}

.orb-inner {
  z-index: 3;
}

.orb-outer-1 {
  transition: all 0.2s ease-in-out;
  background-color: #d3d3d3;
  box-shadow: 0 0 20px 10px #d3d3d398;

  z-index: 2;
}

.orb-outer-2 {
  transition: all 0.2s ease-in-out;
  background-color: #e5e5e5;
  box-shadow: 0 0 20px 10px #e5e5e598;

  z-index: 1;
}

.orb-default {
  background-color: #35c3c1;
  box-shadow: 0 0 13px 5px #35c3c1b5;
}

.orb-speaking {
  transition: all 0.3s ease-in-out;
  background-color: #f07b28;
  box-shadow: 0 0 13px 5px #f07b2897;
  transform: scale(1.2);
}

.orb-thinking {
  transition: all 0.3s ease-in-out;
  background-color: #de5c0b;
  box-shadow: 0 0 13px 5px #de5c0b97;

  transform: scale(0.9);
}




#activate-icon {
  filter: invert(19%) sepia(23%) saturate(0%) hue-rotate(290deg) brightness(95%) contrast(97%);
  height: 30%;
  width: 30%;
}

#central-message-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

#central-message {
  transition: all 1.0s ease-in-out;
  opacity: 1;

  color: #555;
  font-size: 40px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 200;
  text-align: center;
}

#user-manual {
  margin-top: 12vh;
  color: #808080;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}

#download-button {
  transition: all 0.2s ease-in-out;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 4vh;
  box-shadow: none;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-transform: none;
  border: none;
  background-color: #dedede;
  color: #8b8b8b;
}

#download-button:hover {
  transition: all 0.2s ease-in-out;
  background-color: #cdcdcd;
}

#download-button:active {
  transition: all 0.2s ease-in-out;
  background-color: #b2b2b2;
}


.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}



.scale-enter {
  transform: scale(0);
}

.scale-enter-active {
  transform: scale(1);
  transition: transform 500ms;
}

.scale-exit {
  transform: scale(1);
}

.scale-exit-active {
  transform: scale(0);
  transition: transform 500ms;
}

.modal {
  width: 50vw;
  background-color: var(--main-bg);
  border-radius: 40px;
  white-space: pre-line;
  padding: 5vh 4vw;
  box-sizing: border-box;
}

#help-modal-title {
  color: #333;
  font-size: 30px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-align: center;
}

#help-modal-text {
  color: #555;
  font-size: 20px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  margin: 5vh 0 6vh 0;
  line-height: 24px;
}

.text-button {
  border: none;
  border-radius: 40px;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  color: #fff;
  padding: 12px 20px;
  font-weight: 500;
  font-family: 'Hind Vadodara', sans-serif;
}

#modal-start-btn {
  background-color: #4699c3;
}

#modal-start-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: #397a9a;
}

#modal-start-btn-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


#feedback-btn {
  transition: all 0.2s ease-in-out;
  background-color: #dedede;
  color: #8b8b8b;
}

#feedback-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: #cdcdcd;
}


#unavailable-cont {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-bg);
  padding: 0 15vw;
  box-sizing: border-box;

  color: #555;
  font-size: 40px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 200;
  text-align: center;
}

@keyframes rotateText {
  0% {opacity: 0;}
  20% {opacity: 1;}
  80% {opacity: 1;}
  100% {opacity: 0;}
}

#sub-orb-cont {
  display: flex;
  flex-grow: 1;
  margin-top: 6vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#sub-orb-instruction {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #333;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  margin-top: 5vh;
}

#use-cases-rotating {
  width: 100%;
  text-align: center;
  margin-top: 8vh;
  font-size: 20px;
  color: #626262;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 400;
  animation: rotateText 4s ease-in-out;
  animation-iteration-count: infinite;
}



#integrations-modal-title {
  color: #333;
  font-size: 30px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-align: center;
}

#integrations-modal-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4vh 0 3vh 0;
}

#integrations-btn {
  margin-left: 20px;
  transition: all 0.2s ease-in-out;
  color: #8b8b8b;
  background-color: var(--main-bg);
}

#integrations-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: var(--main-bg);
}


.integrations-card {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background-color: #fff;
}


.integrations-card-title {
  color: #555;
  font-size: 20px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-align: left;
}


.integrations-card-button {
  transition: all 0.2s ease-in-out;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  box-shadow: none;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  text-transform: none;
  border: none;

  background-color: #dedede;
  color: #8b8b8b;
}

.integrations-card-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: #cdcdcd;
}


.integrations-card-btn-enabled {
  background-color: #c45555 !important;
  color: #712525 !important;
}

.integrations-card-btn-enabled:hover {
  transition: all 0.2s ease-in-out;
  background-color: #a84545 !important;
}

#integrations-card-waiting-txt {
  color: #888888;
  font-size: 16px;
  font-family: 'Hind Vadodara', sans-serif;
  font-weight: 500;
  padding: 0 10px;
}